import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "p-4 md:p-8" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "flex justify-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CustomRadioOptions = _resolveComponent("CustomRadioOptions")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "1200px",
    visible: _ctx.visibleModel,
    footer: null,
    "mask-closable": !_ctx.preventClose,
    closable: !_ctx.preventClose,
    onCancel: _ctx.handleCancel,
    "destroy-on-close": "",
    centered: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_typography_title, { level: 5 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.headerLabel || _ctx.t("Terms & Conditions")), 1)
            ]),
            _: 1
          }),
          (_ctx.subheaderLabel)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 0,
                class: "text-lg my-8"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subheaderLabel), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_FormGroup, {
          model: _ctx.formState,
          onFinish: _ctx.handleConfirm
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputCheckbox, {
              name: "selectedOptions",
              value: _ctx.formState.selectedOptions,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.selectedOptions) = $event)),
              rules: [
            _ctx.makeAllSelectedRule(
              _ctx.t('Please agree to all terms and conditions.'),
              _ctx.options?.length ?? 0
            ),
          ],
              multiple: "",
              class: "terms-and-conditions-modal__toc"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomRadioOptions, {
                  "selected-values": _ctx.formState.selectedOptions,
                  "onUpdate:selected-values": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.selectedOptions) = $event)),
                  options: _ctx.options,
                  span: 24,
                  multiple: ""
                }, null, 8, ["selected-values", "options"])
              ]),
              _: 1
            }, 8, ["value", "rules"]),
            _createVNode(_component_a_typography_paragraph, { class: "text-center my-8" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t(
              "By proceeding to the next step, you acknowledge and accept all the specific terms and conditions outlined in our agreement."
            )), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.preventClose)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 0,
                    type: "secondary",
                    class: "w-52",
                    onClick: _ctx.handleCancel
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Cancel")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_button, {
                type: "primary",
                "html-type": "submit",
                class: "w-52"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Agree")), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["model", "onFinish"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "mask-closable", "closable", "onCancel"]))
}