
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import KycIcon from "@/shared/components/Icons/KycIcon.vue";

export default defineComponent({
  props: {
    value: { type: String, required: true },
    videoCount: Number,
    performerCount: Number,
    lockInPeriod: Number,
    selected: Boolean,
    hideLockInPeriod: Boolean,
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  components: {
    KycIcon,
  },
});
