import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "subscription-plan-radio__cards" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycIcon = _resolveComponent("KycIcon")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_radio = _resolveComponent("a-radio")!

  return (_openBlock(), _createBlock(_component_a_card, {
    class: _normalizeClass(["subscription-plan-radio", [_ctx.selected && 'subscription-plan-radio--selected']])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_radio, { value: _ctx.value }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_card, { class: "h-20 flex items-center px-6" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_KycIcon, { name: "videoIcon" }),
                  _createVNode(_component_a_typography_text, { class: "subscription-plan-radio__title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("video", _ctx.videoCount ?? 0)), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_a_card, { class: "h-20 flex items-center px-6" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_KycIcon, { name: "performerIcon" }),
                  _createVNode(_component_a_typography_text, { class: "subscription-plan-radio__title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("model creator", _ctx.performerCount ?? 0)), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }),
            (!_ctx.hideLockInPeriod)
              ? (_openBlock(), _createBlock(_component_a_card, {
                  key: 0,
                  class: "h-20 flex items-center px-6"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_KycIcon, {
                        name: _ctx.lockInPeriod ? 'calendar' : 'calendarNotAllowed'
                      }, null, 8, ["name"]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_a_typography_text, { class: "subscription-plan-radio__title" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.lockInPeriod
                    ? _ctx.t("month", _ctx.lockInPeriod ?? 0)
                    : _ctx.t("cancel anytime")), 1)
                          ]),
                          _: 1
                        }),
                        (_ctx.lockInPeriod)
                          ? (_openBlock(), _createBlock(_component_a_typography_text, {
                              key: 0,
                              class: "block text-xs"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("committed subscription")), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    _: 1
  }, 8, ["class"]))
}