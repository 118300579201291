import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_VideoEmbedCarousel = _resolveComponent("VideoEmbedCarousel")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.selectedReasonData)
        ? (_openBlock(), _createBlock(_component_a_row, {
            key: _ctx.selectedReasonData.key,
            gutter: 16,
            align: "middle",
            class: "order-reason-preview"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 24,
                lg: 13
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_typography_title, { level: 5 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.selectedReasonData.header), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_paragraph, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.selectedReasonData.description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: 24,
                lg: 11
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_VideoEmbedCarousel, {
                    "embed-urls": _ctx.selectedReasonData.videoUrls
                  }, null, 8, ["embed-urls"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}