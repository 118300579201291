
import Card from "@/shared/components/Card.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import InputRadio from "@/shared/components/Forms/InputRadio.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import KycIcon from "@/shared/components/Icons/KycIcon.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import OrderReasonPreview from "@/shared/components/OrderReasonPreview.vue";
import { useQuotation } from "@/shared/composables/useQuotation";
import { useLatestQuotation } from "@/shared/composables/useLatestQuotation";
import { useQuotationOptions } from "@/shared/composables/useQuotationOptions";
import { useQuotationPrices } from "@/shared/composables/useQuotationPrices";
import { useGlobalModal } from "@/shared/composables/useGlobalModal";
import { useWindowSizeChecker } from "@/shared/utils/windowSizeChecker";
import { useQuotationForm } from "@/shared/composables/useQuotationForm";
import { useLatestOrder } from "@/shared/composables/useLatestOrder";
import { config } from "@/shared/utils/config";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import CustomRadioOptions from "@/shared/components/CustomRadioOptions.vue";
import TermsAndConditionsModal from "@/shared/components/TermsAndConditionsModal.vue";
import ReuseQuotationModal from "@/shared/components/ReuseQuotationModal.vue";
import QuotationQuestionnaireSection from "@/web/views/Quotation/QuotationQuestionnaireSection.vue";
import SubscriptionPlanRadioOption from "@/shared/components/SubscriptionPlanRadioOption.vue";
import { defineComponent, computed, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { OrderStatus } from "@/shared/types/OrderStatus";
import { testimonialTermsOptions } from "@/shared/utils/termsAndConditions";
import { QUOTATION_STATUS } from "@/shared/types/QuotationStatus";
import routePaths from "@/web/router/routePaths";
import { useStickyFix } from "@/shared/composables/useStickyFix";

export default defineComponent({
  setup() {
    const { t, n } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const quotationIdFromUrl = ref(String(route.query.quotationId ?? ""));

    const isConfirmed = computed(
      () => (route.query.confirmed && route.query.confirmed === "true") ?? false
    );

    const { openGlobalModal: showReuseQuotationModal } =
      useGlobalModal("REUSE_QUOTATION");

    const { quotation: quotationFromUrl } = useQuotation({
      quotationId: quotationIdFromUrl,
    });

    const { latestQuotation, latestQuotationLoading, latestQuotationHasOrder } =
      useLatestQuotation();

    const { latestOrderQuotation } = useLatestOrder(true);

    const reuseModalShown = ref(isConfirmed.value);

    watchEffect(() => {
      /** If we're loading without quotationId and if user has an
       * order and if the order has quotation */
      const showOnThisStatuses = [
        QUOTATION_STATUS.NEW,
        QUOTATION_STATUS.CLIENT_SUBMITTED,
      ];
      if (
        route.path === routePaths.quote &&
        !reuseModalShown.value &&
        !quotationIdFromUrl.value &&
        (latestQuotationHasOrder.value ||
          (latestOrderQuotation?.value &&
            showOnThisStatuses.includes(
              latestQuotation.value?.status?.key ?? ""
            )))
      ) {
        showReuseQuotationModal();
        reuseModalShown.value = true;
      }
    });

    /* If quotation from URL query is not provided,
     * then use latest quotation */
    const quotationToLoad = computed(
      () => quotationFromUrl.value || latestQuotation.value
    );

    const { getQuotationPrice } = useQuotationPrices();

    const { openGlobalModal: showLearnMoreModal } =
      useGlobalModal("LEARN_MORE");

    const { isMobile } = useWindowSizeChecker();

    const {
      orderReasonsOptions,
      scriptAddOnOptions,
      extendedLengthAddOnOptions,
      choosePerformersAddOnOptions,
      usePremiumPerformersAddOnOptions,
      useExpressAddOnOptions,
      usePerformerTravelAddOnOptions,
      sendProductsAddOnOptions,
      useSameDayDeliveryOptions,
      returnProductsAddOnOptions,
      revisionRequestsAddOnOptions,
      subscriptionPlansOptions,
      useTestimonialPackageOptions,
      quotationOptionsLoading,
    } = useQuotationOptions({ quotationRef: quotationToLoad });

    const {
      quotationFormState,
      choosePerformerSelected,
      sendProductsSelected,
      isExpressSelected,
      selectedSubscriptionPlanLimits,
      basePrice,
      pricePerVideoWithAddon,
      pricePerCreatorWithAddon,
      totalQuotationPrice,
      submitQuotationForm,
      allOrderReasonsSelected,
      handleSelectAllOrderReasons,
      submitQuotationFormLoading,
      handleChoosePerformerChange,
      handleSendProductChange,
      handleExpressChange,
      showTestimonialConfirmModal,
      handleTestimonialChange,
      handleTestimonialCancel,
      testimonialPackageSelected,
      packageText,
    } = useQuotationForm({ quotationRef: quotationToLoad });

    const previousSubscriptionPlanId = ref("");
    const handleShowSubscriptionTermsModal = () => {
      previousSubscriptionPlanId.value = quotationFormState.subscriptionPlanId;
    };

    // Sticky Fix for Mobile
    useStickyFix("quotation-questionnaire__footer");

    return {
      t,
      n,
      config,
      router,
      isMobile,
      makeRequiredRule,
      getQuotationPrice,
      showQuotationWarning: computed(() =>
        [OrderStatus.CLIENT_FEEDBACK, OrderStatus.DONE].includes(
          quotationToLoad.value?.order?.status?.key as OrderStatus
        )
      ),

      // Options
      orderReasonsOptions,
      scriptAddOnOptions,
      extendedLengthAddOnOptions,
      choosePerformersAddOnOptions,
      usePremiumPerformersAddOnOptions,
      useExpressAddOnOptions,
      usePerformerTravelAddOnOptions,
      sendProductsAddOnOptions,
      useSameDayDeliveryOptions,
      returnProductsAddOnOptions,
      revisionRequestsAddOnOptions,
      subscriptionPlansOptions,
      useTestimonialPackageOptions,
      quotationOptionsLoading,
      latestQuotationLoading,

      // Quotation Form-related
      quotationFormState,
      choosePerformerSelected,
      sendProductsSelected,
      isExpressSelected,
      selectedSubscriptionPlanLimits,
      basePrice,
      pricePerVideoWithAddon,
      pricePerCreatorWithAddon,
      totalQuotationPrice,
      submitQuotationForm,
      allOrderReasonsSelected,
      handleSelectAllOrderReasons,
      submitQuotationFormLoading,
      handleChoosePerformerChange,
      handleSendProductChange,
      handleExpressChange,

      showTestimonialConfirmModal,
      testimonialPackageSelected,
      handleTestimonialCancel,
      handleTestimonialChange,

      packageText,
      showLearnMoreModal,
      testimonialTermsOptions,

      handleShowSubscriptionTermsModal,
    };
  },
  components: {
    PageLayout,
    FormGroup,
    Card,
    InputRadio,
    InputCheckbox,
    InputText,
    CustomRadioOptions,
    TermsAndConditionsModal,
    SubscriptionPlanRadioOption,
    AppIcon,
    KycIcon,
    OrderReasonPreview,
    QuotationQuestionnaireSection,
    ReuseQuotationModal,
  },
});
