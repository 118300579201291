
import CustomRadioOptions, {
  CustomRadioOption,
} from "@/shared/components/CustomRadioOptions.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { PropType, defineComponent, reactive } from "vue";
import { useVModel } from "vue-composable";
import { useI18n } from "vue-i18n";
import { makeAllSelectedRule } from "@/shared/utils/validators/commonValidators";

export default defineComponent({
  emits: ["on-cancel", "on-confirm"],
  props: {
    options: Array as PropType<CustomRadioOption[]>,
    visible: Boolean,
    headerLabel: String,
    subheaderLabel: String,
    preventClose: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const visibleModel = useVModel(props, "visible");

    const formState = reactive({
      selectedOptions: [],
    });

    const handleCancel = () => {
      if (props.preventClose) {
        return;
      }

      visibleModel.value = false;
      formState.selectedOptions = []; // clear selection on cancel
      emit("on-cancel");
    };

    const handleConfirm = () => {
      visibleModel.value = false;
      formState.selectedOptions = []; // clear selection on cancel
      emit("on-confirm");
    };

    return {
      t,
      visibleModel,
      handleConfirm,
      handleCancel,
      formState,
    };
  },
  components: { InputCheckbox, CustomRadioOptions, FormGroup },
  methods: { makeAllSelectedRule },
});
