import { LatestQuotation_latestQuotation_Quotation } from "@/api/quotations/__generated__/LatestQuotation";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { useUpdateQuotation } from "@/shared/composables/useUpdateQuotation";
import { useQuotationOptions } from "@/shared/composables/useQuotationOptions";
import {
  PricingVariableName,
  useQuotationPrices,
} from "@/shared/composables/useQuotationPrices";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { computed, reactive, watch, Ref, ref } from "vue";
import { i18nTranslate } from "@/plugins/i18n";
import { useRedirectHelper } from "./useRedirectHelper";
import { OrderReason } from "__generated__/globalTypes";

export type UseQuotationFormParameters = {
  /** Used to pre-fill values when provided with existing quotation object. */
  quotationRef: Ref<LatestQuotation_latestQuotation_Quotation | null>;
};

/** Helper function to convert "true" string values to boolean */
const checkIfTrue = (value: string | string[]): boolean => {
  return (
    (typeof value === "string" && value === "true") ||
    (Array.isArray(value) && value[0] === "true")
  );
};

/**
 * A composable that exposes a usable form state to provide the following
 * in relation to Quotation:
 * - Realtime price update based on form state
 * - Realtime options state and updates based on form state, ie. disabled
 */
export const useQuotationForm = (options: UseQuotationFormParameters) => {
  const { redirectToQuoteSummary } = useRedirectHelper();

  const { getSubscriptionPlanLimitsById, getSubscriptionPlanByLimits } =
    useSubscriptionPlans(true);

  const { getQuotationPrice } = useQuotationPrices();

  const { orderReasonsOptions, subscriptionPlansOptions } =
    useQuotationOptions();

  const { updateQuotation, updateQuotationLoading } = useUpdateQuotation();

  const { businessProfile, saveBusinessProfile, updateBusinessProfileLoading } =
    useBusinessProfile();

  /**
   * Values are checked against string literals "true" and "false".
   *
   * Pre-select all "false" (not using add-on), except for useScript (hireStrategist)
   * See: #865cbn1dt
   */
  const quotationFormState = reactive({
    orderReasons: [] as OrderReason[],
    useScript: "true",
    useExtendedVideo: "false",
    useChoosePerformers: "false",
    performersPreference: "",
    usePremiumPerformers: "false",
    useExpress: "false",
    usePerformerTravel: "false",
    sendProducts: "false",
    useSameDayDelivery: "false",
    returnProducts: "false",
    useTestimonialPackage: "false",
    revisionRequests: "false",
    subscriptionPlanId: "",
  });

  const choosePerformerSelected = computed(() =>
    checkIfTrue(quotationFormState.useChoosePerformers)
  );

  const sendProductsSelected = computed(() =>
    checkIfTrue(quotationFormState.sendProducts)
  );

  const testimonialPackageSelected = computed(() =>
    checkIfTrue(quotationFormState.useTestimonialPackage)
  );

  const isExpressSelected = computed(() =>
    checkIfTrue(quotationFormState.useExpress)
  );

  // === For Total Price Computation ===
  type FormStateKey = keyof typeof quotationFormState;

  const PRICING_MAP_PER_VIDEO: Partial<
    Record<FormStateKey, PricingVariableName>
  > = {
    useScript: "ORDER_VIDEO_ADD_ON_STRATEGIST_PRICE",
    useExtendedVideo: "ORDER_VIDEO_ADD_ON_LENGTH_PRICE",
    useChoosePerformers: "ORDER_VIDEO_ADD_ON_PC_CHOOSE_PRICE",
    usePremiumPerformers: "ORDER_VIDEO_ADD_ON_PC_PREMIUM_PRICE",
    useExpress: "ORDER_VIDEO_ADD_ON_EXPRESS_PRICE",
    returnProducts: "ORDER_VIDEO_ADD_ON_PRODUCT_RETURN_PRICE",
    revisionRequests: "ORDER_VIDEO_ADD_ON_REVISION_REQUESTS_PRICE",
    useSameDayDelivery: "ORDER_VIDEO_ADD_ON_SAME_DAY_DELIVERY_PRICE",
  };

  const PRICING_MAP_ONE_TIME: Partial<
    Record<keyof typeof quotationFormState, PricingVariableName>
  > = {
    // TODO: For future use, if any add-ons is computed as one-time fee
  };

  const PRICING_MAP_PER_CREATOR: Partial<
    Record<keyof typeof quotationFormState, PricingVariableName>
  > = {
    usePerformerTravel: "ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE",
  };

  const selectedSubscriptionPlanLimits = computed(() =>
    getSubscriptionPlanLimitsById(quotationFormState.subscriptionPlanId)
  );

  const basePrice = computed(() =>
    checkIfTrue(quotationFormState.useTestimonialPackage)
      ? getQuotationPrice("ORDER_VIDEO_BASE_TESTIMONIAL_PRICE")
      : getQuotationPrice("ORDER_VIDEO_BASE_PRICE")
  );

  const pricePerVideoWithAddon = computed(() => {
    let perVideoAddOnPrice = 0;

    // Per Video
    Object.keys(PRICING_MAP_PER_VIDEO).forEach((key) => {
      const value = quotationFormState[key];

      if (checkIfTrue(value)) {
        const priceVariableName = PRICING_MAP_PER_VIDEO[key];
        perVideoAddOnPrice += getQuotationPrice(priceVariableName);
      }
    });

    return basePrice.value + perVideoAddOnPrice;
  });

  const pricePerCreatorWithAddon = computed(() => {
    let perCreatorAddOnPrice = 0;

    // Per Creator
    Object.keys(PRICING_MAP_PER_CREATOR).forEach((key) => {
      const value = quotationFormState[key];

      if (checkIfTrue(value)) {
        const priceVariableName = PRICING_MAP_PER_CREATOR[key];
        perCreatorAddOnPrice += getQuotationPrice(priceVariableName);
      }
    });

    return perCreatorAddOnPrice;
  });

  const totalQuotationPrice = computed(() => {
    const { numberOfVideos, numberOfPerformers } =
      selectedSubscriptionPlanLimits.value;

    // One-time Fees
    let oneTimeAddOnPrice = 0;
    Object.keys(PRICING_MAP_ONE_TIME).forEach((key) => {
      const value = quotationFormState[key];

      if (checkIfTrue(value)) {
        const priceVariableName = PRICING_MAP_ONE_TIME[key];
        oneTimeAddOnPrice += getQuotationPrice(priceVariableName);
      }
    });

    return (
      numberOfVideos * pricePerVideoWithAddon.value +
      numberOfPerformers * pricePerCreatorWithAddon.value +
      oneTimeAddOnPrice
    );
  });

  // === Mutations ===
  /** Helper function to submit the form that saved to Business Profile and Quotation */
  const submitQuotationForm = async () => {
    if (!options.quotationRef.value) {
      return;
    }

    try {
      // Update KYC Order Reason
      const kycResponse = await saveBusinessProfile({
        input: {
          socialMedia: { orderReasons: quotationFormState.orderReasons },
        },
      });

      // Creates Quotation, if quotationRef is undefined
      // Updates Quotation, if quotationRef has value (and ID)
      const response = await updateQuotation({
        quotationId: options.quotationRef.value.id,
        hireStrategist: checkIfTrue(quotationFormState.useScript),
        useExtendedVideoLength: checkIfTrue(
          quotationFormState.useExtendedVideo
        ),
        // Conditionally save the other performer-related based on "Choose Performer" answer
        choosePerformers: checkIfTrue(quotationFormState.useChoosePerformers),
        usePremiumPerformers: choosePerformerSelected.value
          ? checkIfTrue(quotationFormState.usePremiumPerformers)
          : false,
        performersPreference: choosePerformerSelected.value
          ? quotationFormState.performersPreference
          : "",
        usePerformerTravel: choosePerformerSelected.value
          ? checkIfTrue(quotationFormState.usePerformerTravel)
          : false,
        useExpress: checkIfTrue(quotationFormState.useExpress),
        sendProducts: checkIfTrue(quotationFormState.sendProducts),
        useSameDayDelivery: checkIfTrue(quotationFormState.useSameDayDelivery),
        returnProducts: checkIfTrue(quotationFormState.returnProducts),
        revisionRequests: checkIfTrue(quotationFormState.revisionRequests),
        subscriptionPlanId: quotationFormState.subscriptionPlanId,
        useTestimonialPackage: checkIfTrue(
          quotationFormState.useTestimonialPackage
        ),
      });

      if (!!response && !!kycResponse) {
        redirectToQuoteSummary({ quotationId: options.quotationRef.value.id });
      }
    } catch (e) {
      console.error(e);
    }
  };

  // === On Change Handlers - Used for updating other form state values based on input change ===

  const handleChoosePerformerChange = () => {
    if (!checkIfTrue(quotationFormState.useChoosePerformers)) {
      quotationFormState.usePremiumPerformers = "false";
      quotationFormState.usePerformerTravel = "false";
    }
  };

  const handleSendProductChange = () => {
    if (!checkIfTrue(quotationFormState.sendProducts)) {
      quotationFormState.useSameDayDelivery = "false";
      quotationFormState.returnProducts = "false";
    }
  };

  const handleExpressChange = () => {
    // Disable Performer Travel when Express is enabled
    if (checkIfTrue(quotationFormState.useExpress)) {
      quotationFormState.usePerformerTravel = "false";
    }
  };

  // === Order Reason : Select All
  const allOrderReasonsSelected = computed(
    () => quotationFormState.orderReasons.length === orderReasonsOptions.length
  );
  const handleSelectAllOrderReasons = () => {
    if (allOrderReasonsSelected.value) {
      quotationFormState.orderReasons = [];
    } else {
      quotationFormState.orderReasons = orderReasonsOptions.map(
        (option) => option.value
      ) as OrderReason[];
    }
  };

  // === Use Testimonial Package Logic ===
  const showTestimonialConfirmModal = ref(false);
  /** Shows Testimonial Confirm dialog when new value is "true" */
  const handleTestimonialChange = () => {
    if (checkIfTrue(quotationFormState.useTestimonialPackage)) {
      showTestimonialConfirmModal.value = true;
    }
  };
  /** Resets Testimonial answer and hides modal */
  const handleTestimonialCancel = () => {
    quotationFormState.useTestimonialPackage = "false";
    showTestimonialConfirmModal.value = false;
  };

  // === Load Defaults ===
  watch([options.quotationRef], ([quotation]) => {
    // Pre-fill items only if has submitted already, and is not tied to another order
    // This prevents pre-fill of all false values
    if (quotation) {
      quotationFormState.useScript = String(quotation.hireStrategist);
      quotationFormState.useExtendedVideo = String(
        quotation.useExtendedVideoLength
      );
      quotationFormState.useChoosePerformers = String(
        quotation.choosePerformers
      );
      quotationFormState.performersPreference = quotation.performersPreference;
      quotationFormState.usePremiumPerformers = String(
        quotation.usePremiumPerformers
      );
      quotationFormState.useExpress = String(quotation.useExpress);
      quotationFormState.usePerformerTravel = String(
        quotation.usePerformerTravel
      );
      quotationFormState.sendProducts = String(quotation.sendProducts);
      quotationFormState.useSameDayDelivery = String(
        quotation.useSameDayDelivery
      );
      quotationFormState.returnProducts = String(quotation.returnProducts);
      quotationFormState.useTestimonialPackage = String(
        quotation.useTestimonialPackage
      );
      quotationFormState.revisionRequests = String(quotation.revisionRequests);
    }
  });

  // === Load Default KYC and Subscription ===
  watch(
    [options.quotationRef, subscriptionPlansOptions, businessProfile],
    ([quotation, subscriptionPlansOptions, businessProfile]) => {
      // Pre-fill only KYC Order Reasons if not yet pre-filled
      if (
        !quotationFormState.orderReasons.length &&
        businessProfile?.orderReasons?.length
      ) {
        quotationFormState.orderReasons =
          businessProfile.orderReasons as OrderReason[];
      }

      // Pre-fill subscription plan in order of priority, if not yet pre-filled
      if (!quotationFormState.subscriptionPlanId && quotation) {
        quotationFormState.subscriptionPlanId =
          // Use the selected Plan already in Quote, or
          getSubscriptionPlanByLimits(
            quotation?.numOfVideos,
            quotation?.numOfPerformers
          )?.id ??
          // Use the first option
          subscriptionPlansOptions.at(0)?.value ??
          "";
      }
    }
  );

  return {
    // Form-related
    quotationFormState,
    choosePerformerSelected,
    sendProductsSelected,
    isExpressSelected,
    selectedSubscriptionPlanLimits,

    // Prices
    basePrice,
    pricePerVideoWithAddon,
    pricePerCreatorWithAddon,
    totalQuotationPrice,

    // Handlers
    submitQuotationForm,
    submitQuotationFormLoading: computed(
      () => updateQuotationLoading.value || updateBusinessProfileLoading.value
    ),
    handleChoosePerformerChange,
    handleSendProductChange,
    handleExpressChange,

    // Selected All Reasons
    allOrderReasonsSelected,
    handleSelectAllOrderReasons,

    // Testimonial Package
    testimonialPackageSelected,
    showTestimonialConfirmModal,
    handleTestimonialChange,

    handleTestimonialCancel,

    // Others
    packageText: computed(() => {
      const videos = i18nTranslate(
        "video",
        selectedSubscriptionPlanLimits.value.numberOfVideos
      );
      const creators = i18nTranslate(
        "model creator",
        selectedSubscriptionPlanLimits.value.numberOfPerformers
      );
      const lockIn = i18nTranslate(
        "month",
        selectedSubscriptionPlanLimits.value.testimonialLockInMonths
      );

      if (
        testimonialPackageSelected.value &&
        selectedSubscriptionPlanLimits.value.testimonialLockInMonths
      ) {
        return i18nTranslate("{videos}, {creators}, {lockIn}", {
          videos,
          creators,
          lockIn,
        });
      }

      return i18nTranslate("{videos}, {creators}", {
        videos,
        creators,
      });
    }),
  };
};
