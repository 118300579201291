
import { defineComponent, PropType } from "vue";
import KycIcon, { KycIconName } from "@/shared/components/Icons/KycIcon.vue";
import VideoEmbedCarousel from "@/shared/components/VideoEmbed/VideoEmbedCarousel.vue";
import playButtonWhite from "@/assets/kyc/playButtonWhite.svg";
import { useI18n } from "vue-i18n";

export type CustomRadioOption = {
  label?: string;
  value: string;
  labelIcon?: KycIconName;
  /** Used for highlighted (also large) secondary label */
  subLabel?: string;
  subLabelColor?: "blue" | "pink";
  /** Extra regular text at right */
  subLabelExtra?: string;
  /** To mimic color of sublabel in extra slot (right) */
  useSublabelColorInExtra?: boolean;
  labelDescription?: string;
  image?: string;
  videoUrls?: string[];
  disabled?: boolean;
};

export default defineComponent({
  props: {
    options: Array as PropType<CustomRadioOption[]>,
    selectedValue: String,
    selectedValues: Array,
    imageHeight: [Number, String],
    imageWidth: [Number, String],
    containImages: Boolean,
    multiple: Boolean,
    disabled: Boolean,
  },
  setup() {
    const { t } = useI18n();

    /** Indicates whether to show label description as main label or not */
    const hasNoMainLabel = (option: CustomRadioOption): boolean => {
      return !!option.labelDescription && !option.label;
    };

    const hasExtraContent = (option: CustomRadioOption): boolean => {
      return (
        (!!option.labelDescription && !!option.label) ||
        !!option.subLabel ||
        !!option.image ||
        !!option.videoUrls
      );
    };

    const getSubLabelExtraSlotName = (option: CustomRadioOption): string => {
      return `sublabel-extra--${option.value}`;
    };

    return {
      t,
      playButtonWhite,
      hasExtraContent,
      hasNoMainLabel,
      getSubLabelExtraSlotName,
    };
  },
  components: {
    KycIcon,
    VideoEmbedCarousel,
  },
});
