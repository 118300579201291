import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "quotation-questionnaire z-20"
}
const _hoisted_2 = { class: "my-10 text-center" }
const _hoisted_3 = { class: "quotation-questionnaire__base-price text-lg" }
const _hoisted_4 = { class: "flex items-center gap-2" }
const _hoisted_5 = {
  id: "quotation-questionnaire__footer",
  class: "quotation-questionnaire__footer z-10 mt-10"
}
const _hoisted_6 = { class: "quotation-questionnaire__footer-content flex flex-wrap-reverse justify-end lg:justify-between items-start gap-2 sm:gap-4 px-10 py-6 m-auto" }
const _hoisted_7 = { class: "flex flex-col items-end sm:flex-row sm:items-center gap-2 sm:gap-6" }
const _hoisted_8 = { class: "quotation-questionnaire__package text-right" }
const _hoisted_9 = { class: "quotation-questionnaire__footer-base-price" }
const _hoisted_10 = { class: "text-right" }
const _hoisted_11 = { class: "text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CustomRadioOptions = _resolveComponent("CustomRadioOptions")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_KycIcon = _resolveComponent("KycIcon")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_OrderReasonPreview = _resolveComponent("OrderReasonPreview")!
  const _component_Card = _resolveComponent("Card")!
  const _component_QuotationQuestionnaireSection = _resolveComponent("QuotationQuestionnaireSection")!
  const _component_InputRadio = _resolveComponent("InputRadio")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SubscriptionPlanRadioOption = _resolveComponent("SubscriptionPlanRadioOption")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography = _resolveComponent("a-typography")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_TermsAndConditionsModal = _resolveComponent("TermsAndConditionsModal")!
  const _component_ReuseQuotationModal = _resolveComponent("ReuseQuotationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      _ctx.quotationOptionsLoading ||
      _ctx.latestQuotationLoading ||
      _ctx.reuseLatestQuotationLoading ||
      _ctx.latestOrderLoading
    )
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_typography_title, { level: 2 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("Pricing for your UGC video")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_i18n_t, {
              keypath: "{merchantBrand} video starts at {amount} ({package}).",
              tag: "span",
              class: "text-lg"
            }, {
              merchantBrand: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.config.merchantBrand), 1)
              ]),
              amount: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.n(_ctx.basePrice, "currency", "ph")), 1)
              ]),
              package: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.testimonialPackageSelected ? _ctx.t("Testimonial Plan") : _ctx.t("Basic Plan")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_typography_paragraph, { class: "mt-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("You can customize your video request depending on your need.")), 1)
              ]),
              _: 1
            }),
            (_ctx.showQuotationWarning)
              ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                  key: 0,
                  class: "mt-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t(
            "If you already have a previous subscription and you changed the total amount, we'll reach out to you so we can modify your recurring payments."
          )), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_FormGroup, {
            model: _ctx.quotationFormState,
            loading: _ctx.submitQuotationFormLoading,
            disabled: _ctx.submitQuotationFormLoading,
            onFinish: _ctx.submitQuotationForm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PageLayout, {
                class: "z-0",
                narrow: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, {
                    size: 48,
                    direction: "vertical",
                    class: "flex"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Type of Video')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: _ctx.t(`Where will you use the videos?`),
                            class: "quotation-questionnaire__video-type"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputCheckbox, {
                                name: "orderReasons",
                                value: _ctx.quotationFormState.orderReasons,
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.quotationFormState.orderReasons) = $event)),
                                rules: [
                  _ctx.makeRequiredRule(_ctx.t('This field is required.'), 'array'),
                ],
                                multiple: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-values": _ctx.quotationFormState.orderReasons,
                                    "onUpdate:selected-values": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.quotationFormState.orderReasons) = $event)),
                                    options: _ctx.orderReasonsOptions,
                                    span: 24,
                                    lg: 6,
                                    multiple: ""
                                  }, null, 8, ["selected-values", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules"]),
                              _createElementVNode("label", {
                                class: _normalizeClass(["custom-radio-item mt-4", [
                  _ctx.allOrderReasonsSelected && 'custom-radio-item--selected',
                ]])
                              }, [
                                _createVNode(_component_a_checkbox, {
                                  checked: _ctx.allOrderReasonsSelected,
                                  class: "items-center",
                                  onClick: _withModifiers(_ctx.handleSelectAllOrderReasons, ["prevent"])
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_4, [
                                      _createVNode(_component_KycIcon, { name: "web" }),
                                      _createVNode(_component_a_typography_title, {
                                        level: 5,
                                        class: "custom-radio-item__label"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t("All of the Above")), 1)
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["checked", "onClick"])
                              ], 2),
                              _createVNode(_component_OrderReasonPreview, {
                                "selected-reason": _ctx.quotationFormState.orderReasons.at(-1),
                                class: "mt-8"
                              }, null, 8, ["selected-reason"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Video Brief')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: _ctx.t(`Do you want to write your own video script?`)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "useScript",
                                value: _ctx.quotationFormState.useScript,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.quotationFormState.useScript) = $event)),
                                rules: [
                  _ctx.makeRequiredRule(_ctx.t('This field is required.'), 'string'),
                ]
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useScript,
                                    options: _ctx.scriptAddOnOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Length of Video')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: _ctx.t(`How long are your videos?`)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "useExtendedVideo",
                                value: _ctx.quotationFormState.useExtendedVideo,
                                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.quotationFormState.useExtendedVideo) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useExtendedVideo,
                                    options: _ctx.extendedLengthAddOnOptions,
                                    "image-height": 330,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Model Creator Criteria')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: _ctx.t(`Do you want to choose your model creator?`)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "useChoosePerformers",
                                value: _ctx.quotationFormState.useChoosePerformers,
                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.quotationFormState.useChoosePerformers) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                onChange: _ctx.handleChoosePerformerChange
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useChoosePerformers,
                                    options: _ctx.choosePerformersAddOnOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "onChange"]),
                              _createVNode(_component_a_alert, {
                                message: 
                  _ctx.t(
                    `By choosing NO, you allow {merchantBrand} to choose the best-fitting model creators for you. If you are looking to work with creators in specific demographics or with a specific look, please answer yes to this question.`,
                    {
                      merchantBrand: _ctx.config.merchantBrandShortName,
                    }
                  )
                ,
                                "show-icon": ""
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: "info",
                                    class: "h-4 w-4"
                                  })
                                ]),
                                _: 1
                              }, 8, ["message"]),
                              _createVNode(_component_InputText, {
                                name: "performersPreference",
                                value: _ctx.quotationFormState.performersPreference,
                                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.quotationFormState.performersPreference) = $event)),
                                label: _ctx.t('Your model creator preference'),
                                placeholder: _ctx.t('E.g. Pet owner / a mom / morena skin'),
                                rules: 
                  _ctx.choosePerformerSelected
                    ? [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                    : []
                ,
                                disabled: !_ctx.choosePerformerSelected
                              }, null, 8, ["value", "label", "placeholder", "rules", "disabled"]),
                              _createVNode(_component_InputRadio, {
                                name: "usePremiumPerformers",
                                value: _ctx.quotationFormState.usePremiumPerformers,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.quotationFormState.usePremiumPerformers) = $event)),
                                label: 
                  _ctx.t(
                    'Choose the quality of the model creators you want to work with'
                  )
                ,
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                disabled: !_ctx.choosePerformerSelected
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.usePremiumPerformers,
                                    options: _ctx.usePremiumPerformersAddOnOptions,
                                    span: 24,
                                    md: 12,
                                    disabled: !_ctx.choosePerformerSelected
                                  }, null, 8, ["selected-value", "options", "disabled"])
                                ]),
                                _: 1
                              }, 8, ["value", "label", "rules", "disabled"]),
                              _createVNode(_component_InputRadio, {
                                name: "usePerformerTravel",
                                value: _ctx.quotationFormState.usePerformerTravel,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.quotationFormState.usePerformerTravel) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                disabled: !_ctx.choosePerformerSelected || _ctx.isExpressSelected,
                                label: 
                  _ctx.t(
                    `Do you need our creators to travel to shoot the videos (go to your office, shop, etc.)?`
                  )
                
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.usePerformerTravel,
                                    options: _ctx.usePerformerTravelAddOnOptions,
                                    span: 24,
                                    md: 12,
                                    disabled: !_ctx.choosePerformerSelected || _ctx.isExpressSelected
                                  }, null, 8, ["selected-value", "options", "disabled"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "disabled", "label"]),
                              _createVNode(_component_a_alert, {
                                message: 
                  _ctx.t(
                    `By selecting 'yes', the designated model creator will travel to your specified location to film the videos. Please note that a minimum of {amount} will be added to your bill. If the location is further than 2 hours away from the model creator and/or if the shooting will be longer than 2 hours, additional fee may apply.`,
                    {
                      amount: _ctx.n(
                        _ctx.getQuotationPrice('ORDER_VIDEO_ADD_ON_PC_TRAVEL_PRICE'),
                        'currency',
                        'ph'
                      ),
                    }
                  )
                ,
                                "show-icon": ""
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: "info",
                                    class: "h-4 w-4"
                                  })
                                ]),
                                _: 1
                              }, 8, ["message"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Speed of Delivery')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: _ctx.t(`When do you need your videos?`)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "useExpress",
                                value: _ctx.quotationFormState.useExpress,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.quotationFormState.useExpress) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                onChange: _ctx.handleExpressChange
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useExpress,
                                    options: _ctx.useExpressAddOnOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "onChange"]),
                              _createVNode(_component_a_alert, {
                                message: 
                  _ctx.t(
                    `Day 1 starts after the Model Creator receives the product and/or project briefs.`
                  )
                ,
                                "show-icon": ""
                              }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: "info",
                                    class: "h-4 w-4"
                                  })
                                ]),
                                _: 1
                              }, 8, ["message"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Product Shipment')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "sendProducts",
                                value: _ctx.quotationFormState.sendProducts,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.quotationFormState.sendProducts) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                label: 
                  _ctx.t(
                    `Do you need to ship your products to the creator to finish project?`
                  )
                ,
                                onChange: _ctx.handleSendProductChange
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.sendProducts,
                                    options: _ctx.sendProductsAddOnOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "label", "onChange"]),
                              _createVNode(_component_InputRadio, {
                                name: "useSameDayDelivery",
                                value: _ctx.quotationFormState.useSameDayDelivery,
                                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.quotationFormState.useSameDayDelivery) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                label: 
                  _ctx.t(
                    `Do you need to ship your products through same-day delivery (e.g. Lalamove, Grab)?`
                  )
                ,
                                disabled: !_ctx.sendProductsSelected
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useSameDayDelivery,
                                    options: _ctx.useSameDayDeliveryOptions,
                                    span: 24,
                                    md: 12,
                                    disabled: !_ctx.sendProductsSelected
                                  }, null, 8, ["selected-value", "options", "disabled"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "label", "disabled"]),
                              _createVNode(_component_InputRadio, {
                                name: "returnProducts",
                                value: _ctx.quotationFormState.returnProducts,
                                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.quotationFormState.returnProducts) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                label: 
                  _ctx.t(
                    `Do you need us to return your products after we produce the videos?`
                  )
                ,
                                disabled: !_ctx.sendProductsSelected
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.returnProducts,
                                    options: _ctx.returnProductsAddOnOptions,
                                    span: 24,
                                    md: 12,
                                    disabled: !_ctx.sendProductsSelected
                                  }, null, 8, ["selected-value", "options", "disabled"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "label", "disabled"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Revision Request')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: 
                _ctx.t(
                  `Do you agree that you cannot ask for revision once the videos are uploaded?`
                )
              
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "revisionRequests",
                                value: _ctx.quotationFormState.revisionRequests,
                                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.quotationFormState.revisionRequests) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.revisionRequests,
                                    options: _ctx.revisionRequestsAddOnOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules"]),
                              _createVNode(_component_a_alert, { "show-icon": "" }, {
                                icon: _withCtx(() => [
                                  _createVNode(_component_AppIcon, {
                                    name: "info",
                                    class: "h-4 w-4"
                                  })
                                ]),
                                message: _withCtx(() => [
                                  _createVNode(_component_a_typography_paragraph, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t(
                        "As a default, we don't allow revision*. Instead, we submit 25% more videos so you can have different options."
                      )), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_typography_paragraph, { class: "mb-0" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t(
                        "*Revision accepted: wrong information about the product, typo/grammar error, mispronounced brand name, change background music."
                      )), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_QuotationQuestionnaireSection, {
                        title: _ctx.t('Package')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Card, {
                            title: 
                _ctx.t(
                  `Currently, you are in Basic Package, do you want to subscribe to our Testimonial Package for big discount in exchange of your feedback and agreement to terms?`
                )
              
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_InputRadio, {
                                name: "useTestimonialPackage",
                                value: _ctx.quotationFormState.useTestimonialPackage,
                                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.quotationFormState.useTestimonialPackage) = $event)),
                                rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                                onChange: _ctx.handleTestimonialChange
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CustomRadioOptions, {
                                    "selected-value": _ctx.quotationFormState.useTestimonialPackage,
                                    options: _ctx.useTestimonialPackageOptions,
                                    span: 24,
                                    md: 12
                                  }, null, 8, ["selected-value", "options"])
                                ]),
                                _: 1
                              }, 8, ["value", "rules", "onChange"])
                            ]),
                            _: 1
                          }, 8, ["title"])
                        ]),
                        _: 1
                      }, 8, ["title"]),
                      _createVNode(_component_Card, {
                        title: _ctx.t(`How many videos do you need per month?`)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InputRadio, {
                            name: "subscriptionPlanId",
                            value: _ctx.quotationFormState.subscriptionPlanId,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.quotationFormState.subscriptionPlanId) = $event)),
                            rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_row, { gutter: [32, 18] }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionPlansOptions, (option) => {
                                    return (_openBlock(), _createBlock(_component_a_col, {
                                      span: 24,
                                      lg: 8,
                                      key: option.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_SubscriptionPlanRadioOption, {
                                          value: option.value,
                                          selected: 
                      _ctx.quotationFormState.subscriptionPlanId == option.value
                    ,
                                          "video-count": option.videoCount,
                                          "performer-count": option.performerCount,
                                          "lock-in-period": 
                      _ctx.testimonialPackageSelected
                        ? option.testimonialLockInMonths
                        : 0
                    ,
                                          onClick: _ctx.handleShowSubscriptionTermsModal
                                        }, null, 8, ["value", "selected", "video-count", "performer-count", "lock-in-period", "onClick"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "rules"])
                        ]),
                        _: 1
                      }, 8, ["title"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_button, {
                    type: "link",
                    size: "short",
                    class: "p-0",
                    onClick: _ctx.showLearnMoreModal
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Know more about {merchantBrand}", {
                merchantBrand: _ctx.config.merchantBrandShortName,
              })), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_typography, { class: "quotation-questionnaire__footer-highlight" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t(
                    _ctx.testimonialPackageSelected
                      ? "TESTIMONIAL PACKAGE:"
                      : "BASIC PACKAGE:"
                  )), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_typography, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_i18n_t, { keypath: "{videos}, {creators}" }, {
                            videos: _withCtx(() => [
                              _createVNode(_component_i18n_t, { keypath: "{unit} x {price}" }, {
                                unit: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t(
                            "video",
                            _ctx.selectedSubscriptionPlanLimits.numberOfVideos
                          )), 1)
                                ]),
                                price: _withCtx(() => [
                                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.n(_ctx.basePrice, "currency", "ph")), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            creators: _withCtx(() => [
                              _createVNode(_component_a_typography_text, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t(
                          "model creator",
                          _ctx.selectedSubscriptionPlanLimits.numberOfPerformers
                        )), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (
                  _ctx.testimonialPackageSelected &&
                  _ctx.selectedSubscriptionPlanLimits.testimonialLockInMonths
                )
                        ? (_openBlock(), _createBlock(_component_a_typography_text, {
                            key: 0,
                            class: "ml-1"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t(
                    "month",
                    _ctx.selectedSubscriptionPlanLimits.testimonialLockInMonths
                  )) + " " + _toDisplayString(_ctx.t("committed subscription")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      (!_ctx.isMobile)
                        ? (_openBlock(), _createBlock(_component_a_typography, { key: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("Grand Total")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_a_typography_title, {
                        level: 3,
                        class: "m-0"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.n(_ctx.totalQuotationPrice, "currency", "ph")), 1)
                        ]),
                        _: 1
                      }),
                      (!_ctx.isMobile)
                        ? (_openBlock(), _createBlock(_component_a_typography, {
                            key: 1,
                            class: ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t("including add-ons")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        "html-type": "submit",
                        loading: _ctx.submitQuotationFormLoading,
                        class: "ml-4"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t("Next")), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["model", "loading", "disabled", "onFinish"])
        ])),
    _createVNode(_component_TermsAndConditionsModal, {
      visible: _ctx.showTestimonialConfirmModal,
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showTestimonialConfirmModal) = $event)),
      "subheader-label": 
      _ctx.t(
        'Please read this agreement carefully before availing our Testimonial Plan. You agree to be bound by the terms and conditions by checking the following:'
      )
    ,
      options: _ctx.testimonialTermsOptions,
      onOnCancel: _ctx.handleTestimonialCancel
    }, null, 8, ["visible", "subheader-label", "options", "onOnCancel"]),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_ReuseQuotationModal)
    ]))
  ], 64))
}