import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex items-center gap-2" }
const _hoisted_2 = { class: "flex items-center gap-2" }
const _hoisted_3 = {
  key: 2,
  class: "pl-8 flex flex-col gap-2"
}
const _hoisted_4 = {
  key: 1,
  class: "custom-radio-item__preview mt-2"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycIcon = _resolveComponent("KycIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_VideoEmbedCarousel = _resolveComponent("VideoEmbedCarousel")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    gutter: [32, 16],
    class: "custom-radio-options"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_a_col, {
          key: option.value,
          span: _ctx.$attrs.span || 12,
          xxxl: _ctx.$attrs.xxxl,
          xxl: _ctx.$attrs.xxl,
          xl: _ctx.$attrs.xl,
          lg: _ctx.$attrs.lg,
          md: _ctx.$attrs.md,
          sm: _ctx.$attrs.sm,
          xs: _ctx.$attrs.xs
        }, {
          default: _withCtx(() => [
            _createElementVNode("label", {
              class: _normalizeClass(["custom-radio-item", [
          !_ctx.hasExtraContent(option) && 'justify-center',
          // State : Selected
          (option.value === _ctx.selectedValue ||
            _ctx.selectedValues?.includes(option.value)) &&
            'custom-radio-item--selected',
          // State : Disabled
          (option.disabled || _ctx.disabled) &&
            'cursor-not-allowed custom-radio-item--disabled',
        ]])
            }, [
              (!_ctx.multiple)
                ? (_openBlock(), _createBlock(_component_a_radio, {
                    key: 0,
                    value: option.value,
                    disabled: option.disabled
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        (option.labelIcon)
                          ? (_openBlock(), _createBlock(_component_KycIcon, {
                              key: 0,
                              name: option.labelIcon,
                              class: "w-5"
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_a_typography_title, {
                          level: 5,
                          class: "custom-radio-item__label"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(option.label), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value", "disabled"]))
                : (_openBlock(), _createBlock(_component_a_checkbox, {
                    key: 1,
                    value: option.value,
                    disabled: option.disabled
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        (option.labelIcon)
                          ? (_openBlock(), _createBlock(_component_KycIcon, {
                              key: 0,
                              name: option.labelIcon,
                              class: "w-5"
                            }, null, 8, ["name"]))
                          : _createCommentVNode("", true),
                        (_ctx.hasNoMainLabel(option))
                          ? (_openBlock(), _createBlock(_component_a_typography_text, {
                              key: 1,
                              innerHTML: option.labelDescription
                            }, null, 8, ["innerHTML"]))
                          : (_openBlock(), _createBlock(_component_a_typography_title, {
                              key: 2,
                              level: 5,
                              class: "custom-radio-item__label"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option.label), 1)
                              ]),
                              _: 2
                            }, 1024))
                      ])
                    ]),
                    _: 2
                  }, 1032, ["value", "disabled"])),
              (_ctx.hasExtraContent(option))
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (option.labelDescription && !_ctx.hasNoMainLabel(option))
                      ? (_openBlock(), _createBlock(_component_a_typography_text, {
                          key: 0,
                          class: "text-sm"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(option.labelDescription), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", null, [
                      (option.subLabel)
                        ? (_openBlock(), _createBlock(_component_a_typography_title, {
                            key: 0,
                            level: 6,
                            class: _normalizeClass([
                'custom-radio-item__sublabel',
                option.subLabelColor &&
                  `custom-radio-item__sublabel--${option.subLabelColor}`,
              ])
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(option.subLabel), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"]))
                        : _createCommentVNode("", true),
                      (
                option.subLabelExtra ||
                !!_ctx.$slots[_ctx.getSubLabelExtraSlotName(option)]
              )
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass([
                'custom-radio-item__sublabel-extra',
                option.useSublabelColorInExtra &&
                  'custom-radio-item__sublabel-extra--highlighted',
              ])
                          }, [
                            _createVNode(_component_a_typography_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option.subLabelExtra), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _renderSlot(_ctx.$slots, _ctx.getSubLabelExtraSlotName(option))
                          ], 2))
                        : _createCommentVNode("", true)
                    ]),
                    (option.image || option.videoUrls)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (option.videoUrls)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_VideoEmbedCarousel, {
                                  "embed-urls": option.videoUrls
                                }, null, 8, ["embed-urls"])
                              ]))
                            : (option.image)
                              ? (_openBlock(), _createBlock(_component_a_image, {
                                  key: 1,
                                  src: option.image || '#',
                                  height: _ctx.imageHeight || 250,
                                  width: "100%",
                                  preview: false,
                                  class: _normalizeClass([[_ctx.containImages && 'contained'], "z-0"])
                                }, null, 8, ["src", "height", "class"]))
                              : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 2
        }, 1032, ["span", "xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]))
      }), 128))
    ]),
    _: 3
  }))
}