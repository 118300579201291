
import { defineComponent, PropType, computed } from "vue";
import { OrderReason } from "@/shared/types/OrderReason";
import VideoEmbedCarousel from "@/shared/components/VideoEmbed/VideoEmbedCarousel.vue";
import { useI18n } from "vue-i18n";

export type OrderReasonPreviewData = {
  key: OrderReason;
  header: string;
  description: string;
  videoUrls: string[];
};

export default defineComponent({
  props: {
    selectedReason: String as PropType<OrderReason>,
  },
  setup(props) {
    const { t } = useI18n();

    const mockData: OrderReasonPreviewData[] = [
      {
        key: "ADS",
        description: t(
          "This video is perfect for your advertising needs (e.g. FB, IG and Tiktok ads)."
        ),
        header: t("What is a Video Ad?"),
        videoUrls: ["https://player.vimeo.com/video/827974231?h=431f567437"],
      },
      {
        key: "TIKTOK",
        description: t(
          "Tiktok videos are usually shorter, on-trend and more relatable."
        ),
        header: t("What is a TikTok video?"),
        videoUrls: [
          "https://player.vimeo.com/video/827974095?h=e4fdf1c140",
          "https://player.vimeo.com/video/827974133?h=cf79f1cfaf",
        ],
      },
      {
        key: "INSTAGRAM",
        description: t(
          "IG Videos are more aesthetically pleasing than the other video types."
        ),
        header: t("What is an Instagram video?"),
        videoUrls: ["https://player.vimeo.com/video/827973935?h=8652659c01"],
      },
      {
        key: "FACEBOOK",
        description: t(
          "FB videos tend to be longer and are heavy on storytelling."
        ),
        header: t("What is a Facebook video?"),
        videoUrls: [
          "https://player.vimeo.com/video/827973835?h=d8c894aaf0",
          "https://player.vimeo.com/video/827973878?h=6417d59dfb",
        ],
      },
    ];

    const selectedReasonData = computed(() =>
      mockData.find((data) => data.key === props.selectedReason)
    );

    return { selectedReasonData };
  },
  components: {
    VideoEmbedCarousel,
  },
});
